







import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import FilterForm from "@/components/comps/forms/FilterForm.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipListResponseProvider} from "@/services/TakipService";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import TakipList from "@/components/takip/TakipList.vue";


@Component({
    components: {
        TakipList, ItemArrayManager,
        FormDialogButton,  FilterForm, DeleteButton
    }
})
export default class TakipListView extends Vue {
    items: TakipEntity[] | null = null;

    mounted() {
        window.setTimeout(() => {
            this.items = TakipListResponseProvider().data;
            console.log("takipler:", this.items);
        }, 500)
    }

}
